import axios from 'axios';
import {sendRequestTime, sendError} from '@/utils/monitor';
import bdussCheck from '@/utils/bduss_logger';
import store from '@/store/store';
import qs from 'qs';

// 跨域认证信息 header 名
const xsrfHeaderName = 'Authorization';

axios.defaults.timeout = 5000;
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = xsrfHeaderName;
axios.defaults.xsrfCookieName = xsrfHeaderName;

let requestTimestamp = Date.now();

async function getParisAcessToken(params) {
    const sid = '2108';
    // ParisFactory.create 初始化完成后，window[`paris_${sid}`] 被挂载在全局中
    const paris = window[`paris_${sid}`];

    return new Promise(resolve => {
        paris && paris.getAcsInstance((error, acsInstance) => {
            if (error) {
                // 将错误码作为签名，详细原因见4.1异常处理
                resolve(error.code || 600);
                return;
            }
            acsInstance.getSign((error, sign) => {
                if (error) {
                    // 将错误码作为签名，详细原因见4.1异常处理
                    resolve(error.code || 600);
                    return;
                }
                resolve(sign);
            });
        });
    });
}

// 添加请求拦截器
axios.interceptors.request.use(async config => {
    // 在发送请求之前记录时间戳
    requestTimestamp = Date.now();

    const res = await getParisAcessToken();
    if (!/^\d+$/.test(res)) {
        config.headers['Acs-Token'] = res;
    }
    if (config.url === '/selfselect/getmarketrank') {
        config.headers.Accept = 'application/vnd.f1.v1+json';
    } else {
        // 统一增加 pc 标识
        config.headers.Accept = 'application/vnd.finance-web.v1+json';
    }
    return config;
}, error => {
    sendError({
        msg: 'request error',
    });
    return Promise.reject(error);
});


// 添加响应拦截器
axios.interceptors.response.use(response => {
    const requestTime = Date.now() - requestTimestamp;

    // 发送响应拦截时间
    sendRequestTime(requestTime);
    // 拦截bduss
    bdussCheck(JSON.stringify(response));

    return response;
}, error => {
    sendError({
        msg: 'response error',
    });

    return Promise.reject(error);
});

async function request({url, method = 'GET', params = {}, config = {}}) {
    // 检查url为空
    if (!url) {
        return Promise.reject(new Error('URL is required'));
    }
    switch (method) {
        case 'GET':
            return new Promise((resolve, reject) => {
                params.finClientType = 'pc';
                axios.get(url, {params, ...config}).then(res => {
                    try {
                        if (res && +res.status === 200 && res.data) {
                            resolve(res.data);
                        }
                        else {
                            reject();
                        }
                    }
                    catch (err) {
                        reject();
                    }
                }).catch(() => reject({code: 99999}));
            });
        case 'POST':
            return new Promise((resolve, reject) => {
                params.finClientType = 'pc';
                axios.post(url, qs.stringify(params), config).then(res => {
                    try {
                        if (res && +res.status === 200 && res.data) {
                            resolve(res.data);
                        }
                        else {
                            reject();
                        }
                    }
                    catch (err) {
                        reject();
                    }
                }).catch(() => reject({code: 99999})); ;
            });
        default:
            return axios.get(url, {params, ...config});
    }
}

export default request;
