/**
 * 金融 pc 站路由升级
 * 为确保线上已被 SEO 收录的页面可以正常访问，保留旧的 routes.js
 * 之后增加路由或者更新路由均在 new-routes.js 增加和修改，旧的 routes.js 无需改动
 */

const routerMap = [
    {
        rule: '/',
        hideInMenu: true,
        Component: () => import('@/pages/home/home'),
        name: '欢迎',
        page: 'home',
        icon: 'smile',
    },
    {
        rule: '/stock/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/detail/detail'),
        name: '股票详情页',
        page: 'stock',
    },
    {
        rule: '/index/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/detail/detail'),
        name: '指数详情页',
        page: 'index',
    },
    {
        rule: '/block/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/detail/detail'),
        name: '板块详情页',
        page: 'block',
    },
    {
        rule: '/fund/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/detail/detail'),
        name: '基金详情页',
        page: 'fund',
    },
    {
        rule: '/futures/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/futures/futures'),
        name: '期货详情页',
        page: 'futures',
    },
    {
        rule: '/blocklist/:marketType',
        Component: () => import('@/pages/plate/plate'),
        name: '板块列表页',
        page: 'blocklist',
    },
    {
        rule: '/trade-income/:marketType',
        Component: () => import('@/pages/tradeOwnerIncome/tradeOwnerIncome'),
        name: '行业主力净流入',
        page: 'trade-income',
    },
    {
        rule: '/anomaly/:marketType',
        Component: () => import('@/pages/moveBlockRecord/moveBlockRecord'),
        name: '板块异动详情页',
        page: 'anomaly',
    },
    {
        rule: '/hkt-stock/:marketType',
        Component: () => import('@/pages/hktStock/hktStock'),
        name: '港股通股票详情页',
        page: 'hkt-stock',
    },
    {
        rule: '/my/favorite',
        Component: () => import('@/pages/self-select/self-select'),
        name: '自选列表页',
        page: 'myFavorite',
    },
    {
        rule: '/top/:marketType',
        Component: () => import('@/pages/list/list'),
        name: '榜单列表页',
        page: 'top',
    },
    {
        rule: '/futureslist/:itemurl',
        Component: () => import('@/pages/futures/futuresList'),
        name: '期货列表页',
        page: 'futureslist',
    },
    {
        rule: '/globallist',
        Component: () => import('@/pages/global/globalList'),
        name: '全球指数列表页',
        page: 'globallist',
    },
    {
        rule: '/expressnews',
        Component: () => import('@/pages/express-news/express-news'),
        name: '7 * 24 列表页',
        page: 'expressnews',
    },
    {
        rule: '/calendar',
        Component: () => import('@/pages/calendar/calendar'),
        name: '财经日历落地页',
        page: 'calendar',
    },
    {
        rule: '/hotlist',
        Component: () => import('@/pages/hot-list/hot'),
        name: '热榜排名落地页',
        page: 'hotlist',
    },
    {
        rule: '/downloadpage/xiaogu',
        Component: () => import('@/pages/downloadpage/xiaogu'),
        name: '小顾基金宣传页',
        page: 'xiaogu',
    },
    {
        rule: '/downloadpage/pdf',
        Component: () => import('@/pages/downloadpage/pdf'),
        name: '百度股市通',
        page: 'pdf',
    },
    {
        rule: '/downloadpage/xiaogu/about',
        Component: () => import('@/pages/downloadpage/about'),
        name: '小顾基金宣传页',
        page: 'xiaogu',
    },
    {
        rule: '/downloadpage/xiaogu/contact',
        Component: () => import('@/pages/downloadpage/contact'),
        name: '小顾基金宣传页',
        page: 'xiaogu',
    },
    {
        rule: '/foreign/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/detail/detail'),
        name: '外汇详情页',
        page: 'foreign',
    },
    {
        rule: '/downloadpage/gushitong',
        Component: () => import('@/pages/downloadpage/gushitong'),
        name: '股市通宣传页',
        page: 'gushitong',
    },
    {
        rule: '/open-account/open-account',
        Component: () => import('@/pages/open-account/open-account'),
        name: '开户',
        page: 'openAccount',
    },
    {
        rule: '/capital-list/:type/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/capital-list/capital-list'),
        name: '资金c类列表页',
        page: 'capital-list',
    },
    {
        rule: '/downloadpage/article',
        hideInMenu: true,
        Component: () => import('@/pages/rightpaddle/rightpaddle'),
        name: 'rightpaddle备案页面',
        page: 'rightpaddle',
    },
    {
        rule: '/financial-detail/:type/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/financial-detail/financial-detail'),
        name: '财务解读C类页',
        page: 'financial-detail',
    },
    {
        rule: '/rating-algorithm/stock/:marketCode',
        hideInMenu: true,
        Component: () => import('@/pages/rating-algorithm/rating-algorithm'),
        name: '分析算法页',
        page: 'rating-algorithm',
    },
    {
        rule: '/position-institutions-detail/:entityId',
        hideInMenu: true,
        Component: () => import('@/pages/position-institutions-detail/position-institutions-detail'),
        name: '机构详情页',
        page: 'position-institutions-detail',
    },
];



module.exports = {
    // 属于详情页的页面
    detailPage: ['stock', 'index', 'block', 'foreign', 'futures'],
    routerMap: routerMap,
};
