/**
 * 保存当前页面的参数
 * @author v_zhaoxinggang@baidu.com
 */

import store from '@/store/store';
import {formatUrlQuery, formatUrlMarketCode} from '@/utils/util';

/**
 * 将当前页面的参数保存到 store 中
 *
 * @param {string} page 页面标识
 * @param {string} urlQuery 参数字符串
 */
export const setPageParams = (page, urlQuery) => {
    let params = null;
    const {moduleName} = urlQuery;
    switch (page) {
        case 'home':
            const {quotationMarket, hkType} = urlQuery;
            params = {
                moduleName,
                quotationMarket,
                hkType,
            };
            break;
        case 'stock':
        case 'index':
        case 'block':
        case 'fund':
        case 'foreign':
        case 'futures':
            const {market, code} = formatUrlMarketCode(urlQuery.marketCode);
            const {mainTab, sheet, reportAnchor, reportTime, industrySelect,
                newsOrTalks, name = '', subTab = 0, thirdUrl = '', pn = 0, rn = 10} = urlQuery;
            params = {
                // 详情页类型：股票 stock、指数 index、板块 block
                type: page,
                // 股票市场 us、 ab、hk
                market,
                // 股票 code
                code,
                // 用于锚点 (示例: mainTab=财务）可选值如下
                // 股票: 行情、成分股、简况、财务
                // 指数：行情、成分股
                // 板块：行情、成分股
                mainTab,
                // 用于锚点 (示例: sheet=资产负债表）可选值如下
                // 财务: 利润分配表、资产负债表、现金流量表、关键指标
                sheet,
                // 用于锚点 (示例： reportTab=净利润) 可选值如下，线上暂时不会有，预留给以后使用
                // 财务-利润分配表: 总营收、净利润、净利润率 ...
                // 财务-资产负债表: 总资产、总负债、负债率 ...
                // 财务-现金流量表: 经营现金流、投资现金流、筹资现金流 ...
                // 财务-关键指标: ROE、ROA、每股收益、每股净资产、毛利率、负债率、每股现金流、净利润率
                reportAnchor,
                // 用于锚点
                // 财务tab下的财务报表的时间范围，（示例：reportTime=三季报）可选知一一对应即可
                reportTime,
                // 用于锚点
                // 财务tab下的同行业财报期，透传后端，然后拿后端返回的锚点
                industrySelect,
                // 模块的标题
                moduleName,
                name,
                // 用于锚点
                // 行情下面的新闻、股评模块
                newsOrTalks,
                // 咨询股评舆情等tab锚点标识
                subTab,
                // 舆情单条数据锚点
                thirdUrl,
                // 分页参数
                pn,
                rn,
            };
            break;
        case 'top':
            const [type, activeTab, indexType, text] = formatUrlQuery(urlQuery.marketType);
            params = {
                indexType,
                type,
                text,
                activeTab,
            };
            break;
        case 'blocklist':
            const [blockListMarket, blockListType] = formatUrlQuery(urlQuery.marketType);
            params = {
                market: blockListMarket,
                indexType: blockListType,
            };
            break;
        case 'capital-list':
            const {market: capitalMarket, code: capitalCode} = formatUrlMarketCode(urlQuery.marketCode);
            params = {
                ...urlQuery,
                market: capitalMarket,
                code: capitalCode,
                name: decodeURIComponent(urlQuery.name),
            };
            break;
        case 'myFavorite':
            params = urlQuery;
            break;
        case 'trade-income':
            const [blockMarket, blockType] = formatUrlQuery(urlQuery.marketType);
            params = {
                blockMarket,
                blockType,
            };
            break;
        case 'hkt-stock':
            const [hktMarket, hktTitle, hktTagTitle, hktText] = formatUrlQuery(urlQuery.marketType);
            params = {
                hktMarket,
                hktTitle,
                hktTagTitle,
                hktText,
            };
            break;
        case 'hotlist':
            const {
                activeTab: activeId,
                financeType,
                market: marketed,
                type: timeType,
                firstIndustryName = '',
                firstIndustry = '',
            } = urlQuery;
            params = {
                activeTab: activeId,
                financeType,
                market: marketed,
                type: timeType,
                firstIndustryName,
                firstIndustry,
            };
            break;
        case 'financial-detail':
            const {market: financialDetailMarker, code: financialDetailCode} = formatUrlMarketCode(urlQuery.marketCode);
            params = {
                ...urlQuery,
                market: financialDetailMarker,
                code: financialDetailCode,
            };
            break;
        case 'expressnews':
            const {tabIndex = 0, newsId = ''} = urlQuery;
            params = {
                tabIndex,
                newsId,
            };
            break;
        case 'rating-algorithm':
            params = {
                ...formatUrlMarketCode(urlQuery.marketCode),
                ...urlQuery,
            };
            break;
        case 'openAccount':
            params = {
                moduleName,
            };
            break;
        default:
            params = urlQuery;
            break;
    }

    if (params) {
        store.dispatch('setCurPageParams', params);
    }
};